import { Attribution, NoAttribution } from "@hex/common";
import { useMemo } from "react";

import { useQueryParams } from "../route/routes";

export const getAttribution = (params: URLSearchParams): Attribution => {
  if (params == null) {
    return NoAttribution;
  }
  return {
    gclid: params.get("gclid") ?? undefined,
    rhid: params.get("rhid") ?? undefined,
    utm_medium: params.get("utm_medium") ?? undefined,
    utm_name: params.get("utm_name") ?? undefined,
    utm_source: params.get("utm_source") ?? undefined,
  };
};

export const useAttribution = (override?: Attribution): Attribution => {
  const params = useQueryParams();
  return useMemo(
    () => ({ ...getAttribution(params), ...override }),
    [params, override],
  );
};
