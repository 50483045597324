import { useCallback } from "react";

import { useDispatch, useSelector, useStore } from "../redux/hooks";
import { selectEditMode, setEditMode } from "../redux/slices/logicViewSlice";

export function useEditMode(): boolean {
  return useSelector(selectEditMode);
}

export function useGetEditMode(): () => boolean {
  const store = useStore();
  return useCallback(() => selectEditMode(store.getState()), [store]);
}

export function useSetEditMode(): (editMode: boolean) => void {
  const dispatch = useDispatch();
  return useCallback(
    (editMode: boolean) => dispatch(setEditMode(editMode)),
    [dispatch],
  );
}
