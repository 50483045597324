import { useSelector } from "../../redux/hooks";
import {
  CurrentUser,
  currentUserSelectors,
} from "../../redux/slices/currentUserSlice.js";

export function useCurrentUser<T = CurrentUser | null>({
  equalityFn,
  selector,
}: {
  selector?: (cell: CurrentUser | null) => T;
  equalityFn?: (left: T, right: T) => boolean;
} = {}): T {
  return useSelector(
    (state) =>
      selector
        ? selector(currentUserSelectors.select(state))
        : (currentUserSelectors.select(state) as T),
    equalityFn,
  );
}
