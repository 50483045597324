import { useEffect, useState } from "react";

export type ColorScheme = "LIGHT" | "DARK";

// From: https://github.com/anatoliygatt/use-prefers-color-scheme

export function usePrefersColorScheme(): ColorScheme {
  const [preferredColorScheme, setPreferredColorScheme] =
    useState<ColorScheme>("LIGHT");

  useEffect(() => {
    if (!window.matchMedia) {
      setPreferredColorScheme("LIGHT");
      return;
    }

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    setPreferredColorScheme(mediaQuery.matches ? "DARK" : "LIGHT");

    function onChange(event: MediaQueryListEvent): void {
      setPreferredColorScheme(event.matches ? "DARK" : "LIGHT");
    }

    mediaQuery.addEventListener("change", onChange);

    return () => {
      mediaQuery.removeEventListener("change", onChange);
    };
  }, []);

  return preferredColorScheme;
}
