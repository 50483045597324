// Copied and modified from https://github.com/ReactTraining/react-router/blob/f466c8c4156b6fcdb6baf4fcc723758f7eceeb4b/packages/react-router-dom/modules/Link.js#L21
// To use support custom anchor elements, re-use the same onClick logic, etc.
import {
  Location,
  LocationDescriptor,
  LocationDescriptorObject,
  LocationState,
  createLocation,
} from "history";
import React, { useCallback } from "react";
import { LinkProps, useHistory, useLocation } from "react-router-dom";

function isModifiedEvent(event: React.MouseEvent): boolean {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

type ToType<S = LocationState> = LinkProps<S>["to"];

function resolveToLocation<S = LocationState>(
  to: ToType<S>,
  currentLocation: Location<S>,
): LocationDescriptor<S> {
  return typeof to === "function" ? to(currentLocation) : to;
}

function normalizeToLocation<S = LocationState>(
  to: LocationDescriptor<S>,
  currentLocation: Location<S>,
): LocationDescriptorObject<S> {
  if (typeof to === "string") {
    return createLocation<S>(to, undefined, undefined, currentLocation);
  }
  return to;
}

export function useNavigate({
  replace,
  to,
}: Pick<UseOnClickArgs, "to" | "replace">): () => void {
  const history = useHistory();
  const location = useLocation();

  const navigate = useCallback(() => {
    const newLocation = resolveToLocation(to, location);
    const method = replace ? history.replace : history.push;

    // TODO: why do I have to do this, I dont understand typescript types
    if (typeof newLocation === "string") {
      method(newLocation);
    } else {
      method(newLocation);
    }
  }, [history, location, replace, to]);

  return navigate;
}

export interface UseOnClickArgs {
  to: ToType;
  replace?: boolean | undefined;
  target?: string | undefined;
  /**
   * Additional logic to happen on click.
   * Called regardless of whether a navigation event happened or not.
   */
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export function useOnClickProps({
  onClick: propOnClick,
  replace,
  target,
  to,
}: UseOnClickArgs): {
  target: string | undefined;
  href: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
} {
  const history = useHistory();
  const location = useLocation();

  const normalizedLocation = normalizeToLocation(
    resolveToLocation(to, location),
    location,
  );

  const href = normalizedLocation ? history.createHref(normalizedLocation) : "";

  const navigate = useNavigate({ replace, to });

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      propOnClick?.(event);
      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // ignore everything but left clicks
        (!target || target === "_self") && // let browser handle "target=_blank" etc.
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        event.preventDefault();
        navigate();
      }
    },
    [navigate, target, propOnClick],
  );

  return {
    target,
    href,
    onClick,
  };
}
