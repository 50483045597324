import { BroadcastChannel } from "broadcast-channel";
import { useEffect } from "react";

/**
  This hook creates a broadcast channel and listens to it, automatically closing the
  channel when the calling component unmounts. Note that `onMessageHandler` should be
  stable or callbacked to avoid closing/recreating the channel on every render cycle.
 */
export function useListenToBroadcastChannel<T>(
  channelName: string,
  onMessageHandler: (message: T) => void,
): void {
  useEffect(() => {
    const channel: BroadcastChannel<T> = new BroadcastChannel(channelName);
    channel.onmessage = onMessageHandler;
    return () => {
      void channel
        .close()
        .catch((err) =>
          console.error(
            `Failed to close broadcast channel "${channelName}"`,
            err,
          ),
        );
    };
  }, [channelName, onMessageHandler]);
}
