import { AppTheme } from "@hex/common";

import { Theme } from "../theme/common/theme";
import { DARK_THEME, useDarkTheme } from "../theme/themes/darkTheme";
import { LIGHT_THEME, useLightTheme } from "../theme/themes/lightTheme";

import { usePrefersColorScheme } from "./usePrefersColorScheme";

export function getThemeFromName(themeName: AppTheme): Theme {
  if (themeName === AppTheme.SYS_PREF) {
    const prefersDark =
      window.matchMedia?.("(prefers-color-scheme: dark)").matches ?? false;
    return prefersDark ? DARK_THEME : LIGHT_THEME;
  } else {
    return themeName === AppTheme.DARK ? DARK_THEME : LIGHT_THEME;
  }
}

export function useGetThemeFromName(themeName: AppTheme): Theme {
  const preferredColorScheme = usePrefersColorScheme();
  const lightTheme = useLightTheme();
  const darkTheme = useDarkTheme();

  if (themeName === AppTheme.SYS_PREF) {
    return preferredColorScheme === AppTheme.DARK ? darkTheme : lightTheme;
  }

  return themeName === AppTheme.DARK ? darkTheme : lightTheme;
}
