import { Intent } from "@blueprintjs/core";
import { HexId, SpecialVersionType } from "@hex/common";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

import { useToaster } from "../components/common/Toasts";
import { HexSpinner } from "../hex-components";
import { useDuplicateHexMutation } from "../mutations/hex.generated.js";
import { ORG_ID } from "../orgs.js";
import { Routes } from "../route/routes.js";

import { useToggleState } from "./useToggleState.js";

const exportToastKey = "export-toast";

const StyledHexSpinner = styled(HexSpinner)`
  justify-content: flex-start;
`;

export function useDuplicateProject({
  hexId,
  isComponent,
}: {
  hexId: HexId;
  isComponent: boolean;
}): () => void {
  const toaster = useToaster();

  const [
    duplicateLoading,
    ,
    { setFalse: duplicateDoneLoading, setTrue: setDuplicateLoading },
  ] = useToggleState(false);

  const [duplicateHex] = useDuplicateHexMutation();

  useEffect(() => {
    if (duplicateLoading) {
      toaster.show(
        {
          message: <StyledHexSpinner description="Duplicating project..." />,
          timeout: 0,
        },
        exportToastKey,
      );

      return () => {
        toaster.dismiss(exportToastKey);
      };
    }
  }, [duplicateLoading, toaster]);

  return useCallback(async () => {
    setDuplicateLoading();
    await duplicateHex({
      variables: { hexId },
    })
      .then((data) => {
        const newHexId = data.data?.duplicateHex.id;
        if (newHexId != null) {
          const url = Routes.href(
            ORG_ID,
            true,
            isComponent ? Routes.COMPONENT : Routes.LOGIC,
            {
              hexId: newHexId,
              version: SpecialVersionType.DRAFT,
            },
          );
          window.open(url, "_blank")?.focus();
        }
        duplicateDoneLoading();
      })
      .catch((e) => {
        duplicateDoneLoading();
        toaster.show({
          message: `Could not duplicate ${
            isComponent ? "component" : "project"
          }`,
          intent: Intent.DANGER,
        });
        console.error(e);
      });
  }, [
    duplicateDoneLoading,
    duplicateHex,
    hexId,
    isComponent,
    setDuplicateLoading,
    toaster,
  ]);
}
