import {
  AirlockBlockConfig,
  CellType,
  SQLCellBlockConfig,
  assertNever,
} from "@hex/common";
import { useMemo } from "react";
import { shallowEqual } from "react-redux";
import { createSelector } from "reselect";

import { useSelector } from "../redux/hooks";
import { hexVersionMPSelectors } from "../redux/slices/hexVersionMPSlice.js";
import { RootState } from "../redux/store.js";
import { useProjectContext } from "../util/projectContext";

export function useProjectCellCount(): {
  totalCellCount: number;
  airlockCellCount: number;
  nonAirlockCellCount: number;
} {
  const { hexVersionId } = useProjectContext();

  const selector = useMemo(
    () =>
      createSelector(
        (state: RootState) =>
          hexVersionMPSelectors
            .getCellSelectors(hexVersionId)
            .selectEntities(state),
        (state: RootState) =>
          hexVersionMPSelectors
            .getCellContentSelectors(hexVersionId)
            .selectEntities(state),
        (state: RootState) =>
          state.hexVersionMP[hexVersionId]?.cellContentsIdToCellId,
        (cellsState, cellContentsState, cellContentsIdToCellId) => {
          if (
            cellsState == null ||
            cellContentsState == null ||
            !cellContentsIdToCellId
          ) {
            throw new Error(
              `Missing cells state for hex version: ${hexVersionId}`,
            );
          }

          let totalCellCount = 0;
          let airlockCellCount = 0;
          for (const cell of Object.values(cellsState)) {
            if (cell == null) {
              continue;
            }
            const cellContents = cellContentsState[cell.id];
            if (cellContents == null) {
              continue;
            }
            if (cell.deletedDate != null) {
              continue;
            }
            // If a cell has a parent block cell, count it only if it's part of an airlock
            if (cell.parentBlockCellId != null) {
              const blockCellId =
                cellContentsIdToCellId[cell.parentBlockCellId];
              const parentBlockCell = cellContentsState[blockCellId];

              if (
                parentBlockCell?.__typename === "BlockCell" &&
                parentBlockCell.blockConfig
              ) {
                if (AirlockBlockConfig.guard(parentBlockCell.blockConfig)) {
                  airlockCellCount++;
                  totalCellCount++;
                } else if (
                  SQLCellBlockConfig.guard(parentBlockCell.blockConfig)
                ) {
                  // do nothing for smooshed cells, we only count the parent block cell
                } else {
                  assertNever(
                    parentBlockCell.blockConfig,
                    parentBlockCell.blockConfig,
                  );
                }
              }
            }
            // if a cell *is* a parent block cell, count it only if it's a smooshed cell (not an airlock)
            else if (cell.cellType === CellType.BLOCK) {
              if (
                cellContents?.__typename === "BlockCell" &&
                cellContents.blockConfig
              ) {
                if (SQLCellBlockConfig.guard(cellContents.blockConfig)) {
                  totalCellCount++;
                } else if (AirlockBlockConfig.guard(cellContents.blockConfig)) {
                  // do nothing for airlock block cells, we only count the children
                } else {
                  assertNever(
                    cellContents.blockConfig,
                    cellContents.blockConfig,
                  );
                }
              }
            } else {
              totalCellCount++;
            }
          }

          return {
            totalCellCount,
            airlockCellCount,
            nonAirlockCellCount: totalCellCount - airlockCellCount,
          };
        },
      ),
    [hexVersionId],
  );

  return useSelector(selector, shallowEqual);
}
