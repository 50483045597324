import React from "react";

const ScrollOffsetContext = React.createContext<number | null>(null);

// Adds a scroll-margin-top to all header links in markdown
// In the future we might want to make this stacking (i.e. higher level providers are additive, rather than closest provider wins)
export const ScrollOffsetProvider = ScrollOffsetContext.Provider;

export const useScrollOffset = (): number | null =>
  React.useContext(ScrollOffsetContext);
