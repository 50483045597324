import { useAppSessionSelector } from "../appsession-multiplayer/state-hooks/appSessionStateHooks.js";
import { useSelector } from "../redux/hooks.js";
import {
  HexVersionMP,
  hexVersionMPSelectors,
} from "../redux/slices/hexVersionMPSlice.js";
import { RootState } from "../redux/store.js";
import { useProjectContext } from "../util/projectContext.js";
import { useSessionContext } from "../util/sessionContext.js";

interface SessionOrProjectVersionArgs<S extends boolean> {
  safe?: S;
}

type SessionOrProjectVersionEditableResult<S> = S extends false
  ? boolean
  : boolean | undefined;

export function useSessionEditable(): boolean {
  const { sessionEditableOverride } = useSessionContext();
  const sessionEditableState = useAppSessionSelector({
    selector: (appSession) => appSession.canEdit,
  });

  if (sessionEditableOverride != null) {
    return sessionEditableOverride;
  }
  return sessionEditableState;
}

export function useProjectVersionEditable<S extends boolean = false>(
  args: SessionOrProjectVersionArgs<S> = {},
): SessionOrProjectVersionEditableResult<S> {
  const safe = args.safe ?? false;

  // This throws if safe is not true and project context is not initialized.
  const { hexVersionId, projectVersionEditableOverride } = useProjectContext({
    safe,
  }) ?? {
    hexVersionId: undefined,
    projectVersionEditable: undefined,
    projectVersionEditableOverride: undefined,
  };

  const projectVersionEditableState = useSelector((state: RootState) => {
    if (hexVersionId == null) {
      return undefined;
    }

    const hexVersion = hexVersionMPSelectors
      .getHexVersionSelectors(hexVersionId)
      .select(state);
    const selector = (hv: HexVersionMP): boolean => hv.canEdit;
    return selector(hexVersion);
  });

  if (projectVersionEditableOverride != null) {
    return projectVersionEditableOverride;
  }
  return projectVersionEditableState as SessionOrProjectVersionEditableResult<S>;
}
