import {
  GLOBAL_ORG_ID,
  NoAttribution,
  OrgId,
  isValidRedirectUrl,
} from "@hex/common";
import { useEffect } from "react";

import { getUrlOrg } from "../orgs.js";
import { LoginEvent } from "../route/LoginListener.js";
import { Routes, useQueryParams } from "../route/routes.js";

export const onLoginCallback = (
  queryParams: URLSearchParams,
  urlOrgId: OrgId | undefined,
): ((event: MessageEvent<LoginEvent>) => void) => {
  // If no redirect is provided, redirect to login page.
  // Login pages should automatically redirect to home if they were successfully logged in
  // so only in the case that login actually somehow failed will the user re-see the login page again
  const redirectTo =
    queryParams.get("redirectTo") ??
    Routes.LOGIN.getUrl({
      attribution: NoAttribution,
      orgId: urlOrgId,
    });

  return (event: MessageEvent<LoginEvent>): void => {
    if (
      isValidRedirectUrl(redirectTo, window.location.hostname) &&
      LoginEvent.guard(event.data) &&
      // If this login event didn't specify an org or we match orgs, handle it
      (event.data.orgId == null ||
        event.data.orgId === (urlOrgId ?? GLOBAL_ORG_ID))
    ) {
      window.location.href = redirectTo as string;
    }
  };
};

/**
 * This hook creates a postMessage listener for login events, redirecting the client
 * after the event is received.
 *
 * This is a cross-origin-friendly alternative to BroadcastChannel, which breaks down in
 * cross-origin contexts like embeds. In general, if you find yourself using useListenToBroadcastChannel
 * then you likely want to use this hook as well just to cover your bases.
 */
export function useLoginEventListener(): void {
  const queryParams = useQueryParams();
  const urlOrgId = getUrlOrg();

  useEffect(() => {
    const messageCallback = onLoginCallback(queryParams, urlOrgId);
    window.addEventListener("message", messageCallback);
    return () => {
      window.removeEventListener("message", messageCallback);
    };
  }, [queryParams, urlOrgId]);
}
