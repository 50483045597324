import { Intent } from "@blueprintjs/core";
import { HexId } from "@hex/common";
import { Base64 } from "js-base64";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

import { useExportProjectMutation } from "../components/app/HexOptionsMenu.generated";
import { useToaster } from "../components/common/Toasts";
import { HexSpinner } from "../hex-components";

const exportToastKey = "export-toast";

const StyledHexSpinner = styled(HexSpinner)`
  justify-content: flex-start;
`;

export function useExportProject({ hexId }: { hexId: HexId }): () => void {
  const toaster = useToaster();
  const [exportProject, { loading: exportProjectLoading }] =
    useExportProjectMutation();
  useEffect(() => {
    if (exportProjectLoading) {
      toaster.show(
        {
          message: <StyledHexSpinner description="Exporting project..." />,
          timeout: 0,
        },
        exportToastKey,
      );

      return () => {
        toaster.dismiss(exportToastKey);
      };
    }
  }, [exportProjectLoading, toaster]);

  return useCallback(async () => {
    if (hexId) {
      try {
        const { data: exportData } = await exportProject({
          variables: { hexId },
        });
        if (exportData) {
          const { content, filename } = exportData.exportHexVersion;
          saveAs(
            new Blob([Base64.decode(content)], {
              type: "application/x-yaml",
            }),
            filename,
          );
        }
      } catch (err) {
        console.error(err);
        toaster.show(
          {
            message: "Error exporting project",
            intent: Intent.DANGER,
            timeout: 2500,
          },
          "export-error",
        );
      }
    }
  }, [exportProject, hexId, toaster]);
}
