import { MutableRefObject, useEffect, useRef } from "react";

/**
 * This hook makes it possible to get a stable reference to
 * any given value.
 *
 * Useful for interfacing with non reacty code like monaco.
 *
 * Should be very careful using this w/ react code since
 * this may cause hooks to not fire.
 *
 * @param value
 *
 * @returns a value with a stable reference
 */
export function useStableRef<T>(value: T): MutableRefObject<T> {
  const valueRef = useRef(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);
  return valueRef;
}
