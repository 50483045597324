import { useEffect, useState } from "react";

import { logErrorMsg } from "../util/logging.js";

export enum CookieAccessState {
  LOADING = "LOADING",
  ALLOWED = "ALLOWED",
  DENIED = "DENIED",

  /**
   * Temporarily denied pending user permission.
   * The user prompt may automatically be denied by the browser
   * due to a number of reasons outside of our control.
   */
  REQUIRES_USER_PROMPT = "REQUIRES_USER_PROMPT",

  /**
   * We could not figure out what our permission level was
   * for setting cookies. You should probably fail-open here,
   * and attempt a user prompt.
   */
  CANNOT_DETERMINE = "CANNOT_DETERMINE",
}

const tryCookie = (): boolean => {
  // Stolen directly from modernizr
  // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/cookies.js
  try {
    // Create cookie
    document.cookie = "cookietest=1";
    const ret = document.cookie.indexOf("cookietest=") !== -1;
    // Delete cookie
    document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    return ret;
  } catch (_e) {
    return false;
  }
};

export interface AllowsCookieResult {
  cookieAccessState: CookieAccessState;
  requestCookieAccess: () => Promise<void>;
}

/**
 * Checks the browser's current tolerance for cookies.
 *
 * First determines whether the application is running in a top-level context
 * or embedded. If embedded we assume we're cross-origin and need explicit
 * user permission to set cookies.
 *
 * @returns the current permission level and a callback to trigger
 * a user prompt. This callback _must_ be an effect of some user interaction,
 * such as the click of a button. Otherwise it will automatically be denied.
 *
 * @see: https://developer.mozilla.org/en-US/docs/Web/API/Storage_Access_API/Using#checking_and_requesting_storage_access
 */
export function useAllowsCookies(): AllowsCookieResult {
  const [cookieAccessState, setCookieAccessState] = useState(
    CookieAccessState.LOADING,
  );

  // Attempt to figure out if the browser has access to cookies
  useEffect(() => {
    const isIFrame = window.location !== window.top?.location;
    const hasStorageApis =
      document.hasStorageAccess != null &&
      document.requestStorageAccess != null;
    const canSetCookie = tryCookie();

    if (!isIFrame && canSetCookie) {
      // First party cookie + browser allows cookie access
      setCookieAccessState(CookieAccessState.ALLOWED);
    } else if (!isIFrame && !canSetCookie) {
      // Browser does not allow cookies
      console.warn("Cookie access denied: was unable to set test cookie");
      setCookieAccessState(CookieAccessState.DENIED);
    } else if (!hasStorageApis) {
      setCookieAccessState(CookieAccessState.CANNOT_DETERMINE);
    } else {
      // You might fall into this branch if you're embedding in a first-party context (Hex embedding Hex, or local dev).
      // In my testing `hasStorageAccess()` automatically returns `true` in that case, so the fact that we don't
      // catch it explicitly above should be fine.
      document
        .hasStorageAccess()
        .then(async (canSetThirdPartyCookies) => {
          if (canSetThirdPartyCookies) {
            // This might be more complicated than initially thought,
            // but the docs are not very precise in when this answer can be wrong.
            // https://developer.mozilla.org/en-US/docs/Web/API/Document/hasStorageAccess#return_value
            setCookieAccessState(CookieAccessState.ALLOWED);
          } else {
            try {
              const permission = await navigator.permissions.query({
                // @ts-expect-error -- this permission does exist for some browsers, will throw exception if not
                name: "storage-access",
              });

              if (permission.state === "granted") {
                setCookieAccessState(CookieAccessState.ALLOWED);
              } else if (permission.state === "denied") {
                console.warn(
                  "Cookie access denied: permission state was set by browser",
                );
                setCookieAccessState(CookieAccessState.DENIED);
              } else {
                setCookieAccessState(CookieAccessState.REQUIRES_USER_PROMPT);
              }
            } catch (_e) {
              // This means the browser is old or is Safari
              // https://developer.mozilla.org/en-US/docs/Web/API/Storage_Access_API#api.permissions.permission_storage-access
              setCookieAccessState(CookieAccessState.REQUIRES_USER_PROMPT);
            }
          }
        })
        .catch((err) => {
          setCookieAccessState(CookieAccessState.CANNOT_DETERMINE);
          logErrorMsg(err, "Failed to determine cookie access level");
        });
    }
  }, []);

  const requestCookieAccess = async (): Promise<void> => {
    try {
      document.requestStorageAccess().then(
        () => {
          setCookieAccessState(CookieAccessState.ALLOWED);
        },
        () => {
          console.warn(
            "Cookie access denied: requestStorageAccess() was rejected",
          );
          setCookieAccessState(CookieAccessState.DENIED);
        },
      );
    } catch (_err) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/requestStorageAccess#exceptions
      setCookieAccessState(CookieAccessState.DENIED);
    }
  };

  return { cookieAccessState, requestCookieAccess };
}
