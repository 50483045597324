import { gql } from "@apollo/client";
import { OrgId, PartialOrgMagicSettings } from "@hex/common";

import { useGetOrgForMagicSettingsQuery } from "./useOrgForMagicSettings.generated";

gql`
  query GetOrgForMagicSettings($orgId: OrgId!) {
    orgById(orgId: $orgId) {
      id
      allowMagic
      magicSettings
    }
  }
`;

export function useOrgForMagicSettings(orgId: OrgId): {
  orgId: OrgId | undefined;
  magicAllowed: boolean;
  magicSettings: PartialOrgMagicSettings;
} {
  const { data } = useGetOrgForMagicSettingsQuery({
    variables: {
      orgId,
    },
  });

  return {
    orgId: data?.orgById?.id,
    magicAllowed: data?.orgById?.allowMagic ?? false,
    magicSettings: data?.orgById?.magicSettings ?? {},
  };
}
