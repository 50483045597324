import {
  AppSessionId,
  CellExecutionState,
  CellId,
  GLOBAL_REACTIVE_PARAM_TYPES,
  GraphNodeV3,
  HEX_HIDDEN_PREFIX,
  HexVersionId,
  ProjectLanguage,
  StaticCellId,
  getGraphNodeV3Inputs,
  getGraphNodeV3Outputs,
  notEmpty,
} from "@hex/common";
import { keyBy, mapValues } from "lodash";
import { shallowEqual } from "react-redux";
import { createSelector } from "reselect";

import { useConnectionItems } from "../components/cell/renderers/sql/sqlCellHooks";
import { DataConnectionSelectItem } from "../components/data/selector/DataConnectionSelectItemRenderer";
import { useGraphNodeV3Selector } from "../graph/graphNodeV3Hooks";
import { useProjectGraphV3Selector } from "../graph/graphV3Hooks";
import { useCellContentsSelector } from "../hex-version-multiplayer/state-hooks/cellContentsStateHooks";
import { useCellsContentsSelector } from "../hex-version-multiplayer/state-hooks/cellsContentsStateHooks";
import { useHexSelector } from "../hex-version-multiplayer/state-hooks/hexStateHooks";
import { useSelector } from "../redux/hooks.js";
import { appSessionMPSelectors } from "../redux/slices/appSessionMPSlice.js";
import {
  CellMP,
  hexVersionMPSelectors,
} from "../redux/slices/hexVersionMPSlice";
import { RootState } from "../redux/store.js";
import { useProjectContext } from "../util/projectContext.js";
import { useSessionContext } from "../util/sessionContext.js";

export interface RunErrorCell {
  staticId: StaticCellId;
  label: string;
}

const errorCellsSelector = createSelector(
  (state: RootState, hexVersionId: HexVersionId) =>
    hexVersionMPSelectors
      .getCellSelectors(hexVersionId)
      .selectFlattenedSorted(state),
  (state: RootState, hexVersionId: HexVersionId) =>
    hexVersionMPSelectors
      .getCellContentAwareSelectors(hexVersionId)
      .selectCellIdToLabel(state),
  (state: RootState, _: HexVersionId, appSessionId: AppSessionId) =>
    appSessionMPSelectors
      .getAppSessionCellSelectors(appSessionId)
      .selectCellIdToAppSessionCell(state),
  (sortedCells, cellLabels, appSessionCells) => {
    return sortedCells.flatMap((cell) => {
      const appSessionCell = appSessionCells[cell.id];
      if (appSessionCell?.state !== CellExecutionState.ERRORED) {
        return [];
      }
      return { staticId: cell.staticId, label: cellLabels[cell.id] ?? "Cell" };
    });
  },
);

export const useErroredCells = (): RunErrorCell[] => {
  const { hexVersionId } = useProjectContext();
  const { appSessionId } = useSessionContext();
  return useSelector((state) =>
    errorCellsSelector(state, hexVersionId, appSessionId),
  );
};

function shouldSuppressUndefinedReferencesForConnection(
  connection?: DataConnectionSelectItem,
): boolean {
  return Boolean(connection?.hasDbtProxy);
}

// undefined references inferred from the graph can be false positives, so only
// highlight them if the cell is errored or stale
const SHOW_UNDEFINED_REFERENCES_STATES: Set<CellExecutionState> = new Set([
  CellExecutionState.ERRORED,
  CellExecutionState.STALE,
]);

export const useShouldSuppressUndefinedReferences = (
  cellId: CellId,
  state: CellExecutionState,
): boolean => {
  const { connectionItems: dataConnections } = useConnectionItems();
  const shouldSuppressForConnection = useCellContentsSelector({
    cellId,
    selector: (cell) => {
      return (
        cell?.__typename === "SqlCell" &&
        cell.connectionId != null &&
        shouldSuppressUndefinedReferencesForConnection(
          dataConnections.find(({ id }) => id === cell.connectionId),
        )
      );
    },
    equalityFn: shallowEqual,
    safe: true,
  });
  return (
    shouldSuppressForConnection || !SHOW_UNDEFINED_REFERENCES_STATES.has(state)
  );
};

export const useGraphErrorsMap = (
  cellIds: CellId[],
): Record<CellId, string | undefined> => {
  const language = useHexSelector({
    selector: (h) => h.projectLanguage,
  });
  const parseErrors: Record<CellId, string> = useCellsContentsSelector({
    selector: (state) => {
      return Object.values(state).reduce(
        (acc: Record<CellId, string>, cell) => {
          if (
            cell &&
            "sqlCellReferencesV3" in cell &&
            cell.sqlCellReferencesV3?.parseError != null
          ) {
            acc[cell?.cellId] = cell.sqlCellReferencesV3?.parseError;
          }
          if (
            cell &&
            "jinjaCellReferencesV3" in cell &&
            cell.jinjaCellReferencesV3?.parseError != null
          ) {
            acc[cell?.cellId] = cell.jinjaCellReferencesV3?.parseError;
          }
          if (
            cell &&
            "cellReferencesParseError" in cell &&
            cell.cellReferencesParseError?.error != null
          ) {
            acc[cell?.cellId] = cell.cellReferencesParseError?.error;
          }
          return acc;
        },
        {},
      );
    },
    equalityFn: shallowEqual,
  });
  const graphV3 = useProjectGraphV3Selector({
    selector: (g) => g,
  });
  const { connectionItems } = useConnectionItems();
  const dataConnections = keyBy(connectionItems, "id");
  const cellConnections: Record<CellId, DataConnectionSelectItem | undefined> =
    useCellsContentsSelector({
      selector: (state) =>
        mapValues(state, (cell) => {
          if (cell?.__typename === "SqlCell" && cell.connectionId) {
            return dataConnections[cell.connectionId];
          }
        }),
      equalityFn: shallowEqual,
    });

  const errorsMap: Record<CellId, string | undefined> = {};

  cellIds.forEach((cellId) => {
    const graphNodeV3 = graphV3[cellId];
    const parseError = parseErrors[cellId];
    const suppressUndefinedReferences =
      shouldSuppressUndefinedReferencesForConnection(cellConnections[cellId]);

    errorsMap[cellId] = formatError({
      graphNode: graphNodeV3,
      parseError,
      language,
      suppressUndefinedReferences,
    });
  });

  return errorsMap;
};

export const useGraphError = (
  cellId: CellId,
  state: CellExecutionState,
): string | undefined => {
  const language = useHexSelector({
    selector: (h) => h.projectLanguage,
  });
  const parseError: string | undefined = useCellContentsSelector({
    cellId,
    selector: (cell) => {
      if (
        cell &&
        "sqlCellReferencesV3" in cell &&
        cell.sqlCellReferencesV3?.parseError != null
      ) {
        return cell.sqlCellReferencesV3?.parseError;
      }
      if (
        cell &&
        "jinjaCellReferencesV3" in cell &&
        cell.jinjaCellReferencesV3?.parseError != null
      ) {
        return cell.jinjaCellReferencesV3?.parseError;
      }
      if (
        cell &&
        "cellReferencesParseError" in cell &&
        cell.cellReferencesParseError?.error != null
      ) {
        return cell.cellReferencesParseError.error;
      }
    },
    equalityFn: shallowEqual,
    safe: true,
  });
  const graphNode: GraphNodeV3<CellMP> | undefined = useGraphNodeV3Selector({
    nodeId: cellId,
    selector: (g) => g,
    equalityFn: shallowEqual,
    safe: true,
  });
  const suppressUndefinedReferences = useShouldSuppressUndefinedReferences(
    cellId,
    state,
  );

  return formatError({
    graphNode,
    parseError,
    language,
    suppressUndefinedReferences,
  });
};

interface FormatErrorArgs {
  parseError?: string;
  graphNode?: GraphNodeV3<CellMP>;
  language: ProjectLanguage;
  suppressUndefinedReferences?: boolean;
}

const formatError = ({
  graphNode,
  language,
  parseError,
  suppressUndefinedReferences = false,
}: FormatErrorArgs): string | undefined => {
  let overwrittenGlobalsError = null;
  let missingDefinitionsError = null;
  if (graphNode) {
    const inputParams = getGraphNodeV3Inputs(graphNode);
    const outputParams = getGraphNodeV3Outputs(graphNode);

    const overwrittenGlobals = [...outputParams.entries()]
      .filter(([, outputParamInfo]) =>
        GLOBAL_REACTIVE_PARAM_TYPES.includes(outputParamInfo.type),
      )
      .map(([output]) => output);
    overwrittenGlobalsError =
      overwrittenGlobals.length > 0
        ? `Overwritten global value: ${overwrittenGlobals.join(", ")}`
        : null;

    // R AST parsing is extremely hard, so we just skip alerting on undefineds for now because it creates so many false positives
    if (language !== ProjectLanguage.R && !suppressUndefinedReferences) {
      const missingDefinitions = [...inputParams.entries()]
        .filter(
          ([name, inputParamInfo]) =>
            !GLOBAL_REACTIVE_PARAM_TYPES.includes(inputParamInfo.type) &&
            inputParamInfo.parentNodeId == null &&
            !name.includes(HEX_HIDDEN_PREFIX),
        )
        .map(([input]) => input);
      missingDefinitionsError =
        missingDefinitions.length > 0
          ? `Undefined: ${missingDefinitions.join(", ")}`
          : null;
    }
  }
  const parseErrorMessage = parseError && `Parse error: ${parseError}`;

  const combinedErrors = [
    parseErrorMessage,
    missingDefinitionsError,
    overwrittenGlobalsError,
  ].filter(notEmpty);

  if (combinedErrors.length > 0) {
    return combinedErrors.join("\n");
  }
};
